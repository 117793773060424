import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Row, Col } from 'antd';
import useApi from '../../../hooks/useApi';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import AdminContent from '../../../components/adminContent';
import FloatingContainer from '../../../components/floatingContainer'
import { FiCheckCircle } from "react-icons/fi";
import { MdArrowForwardIos } from "react-icons/md";
import { BsArrowRight } from "react-icons/bs";

const OnboardingPage = () => {

  useDocumentTitle("Onboarding")
  const navigate = useNavigate();
  const [sendRequest] = useApi()

  useEffect(() => {
    window.scrollTo(0, 0);
    loadPage()
  }, []);

  const loadPage = async () => {
    try {
      // const checklistResults = await sendRequest(getOnboardingChecklist());
    } catch {}
  }

  const renderHeader = () => {
    return (
      <div className="p-20">
        <div>
          <span className="c-blue fw-700 cursor-default" onClick={() => navigate("/admin/dashboard")}>
            Dashboard
          </span>
          <span className="fs-10 mh-5"><MdArrowForwardIos/></span>
          <span className="cursor-default c-text-gray">
            Getting Started
          </span>
        </div>
        <div className="fw-700 fs-24 mt-5">Getting Started</div>
      </div>
    )
  }

  const renderTaskSection = (title, text, link, complete = false, showDivider = true) => {
    const checkColor = complete ? "#10ac84" : "#999"
    const completedTitle = complete ? "strikethrough c-text-gray" : ""
    return (
      <>
        { showDivider && (
          <div className="b-border mv-20"></div>
        )}
        <Row gutter={[15,15]} wrap={false}>
          <Col flex={0}><FiCheckCircle size={24} color={checkColor}/></Col>
          <Col flex={1}>
            <div className={`fw-700 fs-16 ${completedTitle}`}>{title}</div>
            <div className="">{text}</div>
          </Col>
          <Col flex={0}>
            <div className="blue-link flex-row flex-1 flex-middle" onClick={() => navigate(link)}>Let's go <BsArrowRight style={{ marginLeft: 5 }}/></div>
          </Col>
        </Row>
      </>
    )
  }

  const renderContent = () => {
    return (
      <>
        <FloatingContainer className="ph-20 bg-gray" verticalPadding={30} maxWidth={1000}>
          <div className="shadow-card p-20">
            <div className="fw-700 fs-24">Welcome to DJ Planning Center!</div>
            <div className="mt-10">We know learning a new platform can be challenging, so we want to make it as easy as possible. Let's walk through some steps to get your account setup so you can make the most of your experience.</div>
            { renderTaskSection("Update your General Settings", "Set your timezone, etc.", "/admin/setup/general")}
            { renderTaskSection("Upload your Company Logo", "Improve your brand by uploading a logo that will display on invoices, contracts, etc.", "/admin/setup/branding")}
            { renderTaskSection("Customize your Event Settings", "Set your timezone, etc.", "/admin/setup/general")}
            { renderTaskSection("Setup Special Event Template", "Set your timezone, etc.", "/admin/special-event-templates")}
            { renderTaskSection("Create Packages & Add-Ons", "Configure all of the services your company offers.")}
            { renderTaskSection("Create Questionnaires", "Configure all of the services your company offers.")}
            { renderTaskSection("Setup Contract Templates", "Configure all of the services your company offers.")}
          </div>
        </FloatingContainer>
      </>
    )
  }

  return (
    <AdminContent body={renderContent()} header={renderHeader()}/>
  );
}

export default OnboardingPage;
