import { get, post, put, del, postImage } from "./api";
import AuthService from "./authService";

export const getProspects = () => get('/prospects');

const GET = "GET";
const POST = "POST";
const PUT = "PUT";
const DELETE = "DELETE";

const requestOptions = (method, url, data = null) => {
  return {
    method,
    url,
    data: data
  }
}

// Auth
export const login = (body) => post('/auth/login', body);
export const logout = (user_id, device_id) => post('/auth/logout', { user_id: user_id, source: "web", device_id: device_id});
export const signup = (body) => post('/auth/signup', body);
export const verifyUser = (email) => get(`/auth/verify/user?email=${email}`);
export const verifyEmailConfirm = (body) => post('/auth/verify/email/confirm', body);
export const resentVerificationEmail = (body) => post('/auth/verify/email/resend', body);
export const setPassword = (body) => post('/auth/set/password', body);
export const sendResetPasswordEmail = (body) => post('/auth/reset-password', body);
export const resetPassword = (body) => put('/auth/reset-password', body);
export const changePassword = (body) => requestOptions(PUT, `/auth/change-password`, body);
export const verifyResetPasswordToken = (token) => get(`/auth/reset-password?token=${token}`);
export const sendVerificationEmail = (body) => post('/auth/verification', body);
export const verifyEmail = (token) => get(`/auth/verification?token=${token}`);
export const submitInterest = (body) => post('/auth/interest', body);

export const sendClientInvitation = (data) => requestOptions(POST, `/auth/invitation`, data);
export const verifyClientInvitationToken = (token) => get(`/auth/invitation?token=${token}`);
export const setClientInvitationPassword = (data) => post(`/auth/invitation/password`, data);

export const getAccountDetails = () => requestOptions(GET, `/auth/account`);
export const getPlanLimit = () => requestOptions(GET, `/auth/plan-limit`);
export const updateAccount = (data) => requestOptions(PUT, `/auth/account`, data);
export const updateAccountTaxRate = (data) => requestOptions(PUT, `/auth/account/tax-rate`, data);
export const updateAccountLogo = (data) => requestOptions(PUT, `/auth/account/logo`, data);
export const getAccountCurrency = () => requestOptions(GET, `/auth/account/currency`);
export const updateAccountCurrency = (data) => requestOptions(PUT, `/auth/account/currency`, data);
export const getAccountSettings = () => requestOptions(GET, `/auth/account/settings`);
export const updateAccountDateAndTimeFormat = (data) => requestOptions(PUT, `/auth/account/settings/date-time-format`, data);
export const updateAccountHoldingFeeTerm = (data) => requestOptions(PUT, `/auth/account/settings/holding-fee-term`, data);
export const updateAccountCountry = (data) => requestOptions(PUT, `/auth/account/settings/country`, data);
export const refreshToken = (data) => requestOptions(POST, `/auth/token`, data);
export const getAccountLogo = () => requestOptions(GET, `/auth/logo`);
export const updateAccountClientPortalSettings = (data) => requestOptions(PUT, `/auth/account/settings/client-portal`, data);
export const getAccountClientPortalSettings = () => requestOptions(GET, `/auth/account/settings/client-portal`);
export const getAdminUserDashboard = () => requestOptions(GET, `/auth/dashboard`);
export const getOnboardingChecklist = () => requestOptions(GET, `/auth/onboarding-checklist`);

export const createAdminUser = (data) => requestOptions(POST, `/auth/users`, data);
export const updateAdminUser = (user_id, data) => requestOptions(PUT, `/auth/users/${user_id}`, data);
export const deleteAdminUser = (user_id) => requestOptions(DELETE, `/auth/users/${user_id}`);
export const getAdminUsers = () => requestOptions(GET, `/auth/users`);
export const getUser = (user_id) => requestOptions(GET, `/auth/users/${user_id}`);
export const getAdminUserPermissions = () => requestOptions(GET, `/auth/admin-user-permissions`);
export const updateAdminProfile = (user_id, data) => requestOptions(PUT, `/auth/users/${user_id}/profile`, data);

export const uploadUserPhoto = (data) => requestOptions(POST, `/auth/photo`, data);

export const getAccountSummary = () => requestOptions(GET, `/auth/account/summary`);
export const uploadAccountLogo = (account_id, data) => requestOptions(POST, `/accounts/${account_id}/logo`, data);
export const cancelTrial = (account_id, data) => requestOptions(PUT, `/accounts/${account_id}/cancel-trial`, data);
export const upsertAccountFinancials = (account_id, data) => requestOptions(PUT, `/accounts/${account_id}/financials`, data);
export const getAccountImages = (account_id) => requestOptions(GET, `/accounts/${account_id}/images`);
export const getAccountImage = (account_id, image_key) => requestOptions(GET, `/accounts/${account_id}/images/${image_key}`);
export const uploadAccountImage = (account_id, image_key, data) => requestOptions(POST, `/accounts/${account_id}/images/${image_key}`, data);
export const updateAccountImage = (account_id, image_key, data) => requestOptions(PUT, `/accounts/${account_id}/images/${image_key}`, data);

export const getSelectPlanPromoCodes = () => requestOptions(GET, `/auth/select-plan/promo-codes`);

export const getAdminPdfToken = () => requestOptions(GET, `/auth/pdf-token`);

// Contact
export const submitContactForm = (body) => post('/contact', body);
export const submitSupportTicket = (body) => post('/contact/support', body);

// Stripe
export const getStripeSetupIntent = () => requestOptions(GET, `/stripe/setup-intent`);
export const createStripeSubscription = (body) => requestOptions(POST, `/stripe/create-subscription`, body);
export const updateSubscription = (body) => requestOptions(PUT, `/stripe/update-subscription`, body);
export const cancelSubscription = (body) => requestOptions(DELETE, `/stripe/cancel-subscription`, body);
export const activateAccount = () => requestOptions(POST, `/stripe/activate-account`);

export const getStripeSettings = () => requestOptions(GET, `/stripe/settings`);
export const createStripeAccount = () => requestOptions(POST, `/stripe/account`);
export const getStripeAccountLink = () => requestOptions(GET, `/stripe/account-link`);
export const deleteStripeAccount = () => requestOptions(DELETE, `/stripe/account`);
export const updateStripeBankTransfer = (data) => requestOptions(PUT, `/stripe/bank-transfer`, data);

// PayPal
export const getPayPalSettings = () => requestOptions(GET, `/paypal/settings`);
export const deletePayPalSettings = () => requestOptions(DELETE, `/paypal/settings`);
export const getPayPalSignupLink = () => requestOptions(GET, `/paypal/signup-link`);
export const getPayPalSellerStatus = () => requestOptions(GET, `/paypal/status`);
export const updatePayPalPaymentMethods = (data) => requestOptions(PUT, `/paypal/payment-methods`, data);

// Manual Payment Methods
export const getManualPaymentMethods = () => requestOptions(GET, `/payments/manual-payment-methods`);
export const createManualPaymentMethod = (data) => requestOptions(POST, `/payments/manual-payment-methods`, data);
export const updateManualPaymentMethod = (payment_method_id, data) => requestOptions(PUT, `/payments/manual-payment-methods/${payment_method_id}`, data);
export const deleteManualPaymentMethod = (payment_method_id) => requestOptions(DELETE, `/payments/manual-payment-methods/${payment_method_id}`);

// Event Types
export const getEventTypes = () => requestOptions(GET, `/events/types`);

// Account Event Types
export const getAccountEventTypes = () => requestOptions(GET, `/event-types`);
export const getAccountEventType = (id) => get(`/event-types/${id}`);
export const createAccountEventType = (data) => requestOptions(POST,`/event-types`, data);
export const updateAccountEventType = (id, data) => requestOptions(PUT, `/event-types/${id}`, data);
export const deleteAccountEventType = (id) => requestOptions(DELETE, `/event-types/${id}`);

// Account Admin Event Roles
export const getAccountAdminEventRoles = () => requestOptions(GET, `/admin-event-roles`);
export const getAccountAdminEventRole = (id) => get(`/admin-event-roles/${id}`);
export const createAccountAdminEventRole = (data) => requestOptions(POST,`/admin-event-roles`, data);
export const updateAccountAdminEventRole = (id, data) => requestOptions(PUT, `/admin-event-roles/${id}`, data);
export const deleteAccountAdminEventRole = (id) => requestOptions(DELETE, `/admin-event-roles/${id}`);

// Account Client Event Roles
export const getAccountClientEventRoles = () => requestOptions(GET, `/client-event-roles`);
export const getAccountClientEventRole = (id) => get(`/client-event-roles/${id}`);
export const createAccountClientEventRole = (data) => requestOptions(POST,`/client-event-roles`, data);
export const updateAccountClientEventRole = (id, data) => requestOptions(PUT, `/client-event-roles/${id}`, data);
export const deleteAccountClientEventRole = (id) => requestOptions(DELETE, `/client-event-roles/${id}`);

// Event Templates
export const getEventTemplates = () => requestOptions(GET, `/event-templates`);
export const getEventTemplate = (id) => requestOptions(GET, `/event-templates/${id}`);
export const createEventTemplate = (data) => requestOptions(POST,`/event-templates`, data);
export const modifyEventTemplate = (id, data) => requestOptions(PUT, `/event-templates/${id}`, data);
export const deleteEventTemplate = (id) => requestOptions(DELETE, `/event-templates/${id}`);
export const duplicateEventTemplate = (id) => requestOptions(POST, `/event-templates/${id}`);

// Custom Event Fields
export const getCustomEventFields = () => requestOptions(GET, `/events/custom-event-fields`);
export const getCustomEventField = (id) => requestOptions(GET, `/events/custom-event-fields/${id}`);
export const createCustomEventField = (data) => requestOptions(POST,`/events/custom-event-fields`, data);
export const updateCustomEventField = (id, data) => requestOptions(PUT, `/events/custom-event-fields/${id}`, data);
export const deleteCustomEventField = (id) => requestOptions(DELETE, `/events/custom-event-fields/${id}`);

// Event Settings
export const getAccountEventSettings = () => requestOptions(GET, `/events/event-settings`);

// Forms
export const getForms = () => get(`/forms`);
export const getForm = (id) => get(`/form/${id}`);

// Clients
export const getClients = () => requestOptions(GET, `/clients`);
export const getClientsForExport = () => requestOptions(GET, `/clients/export`);
export const getClient = (userId) => requestOptions(GET, `/clients/${userId}`);
export const createClient = (data) => requestOptions(POST, `/clients`, data);
export const updateClient = (userId, data) => requestOptions(PUT, `/clients/${userId}`, data);
export const deleteClient = (userId) => requestOptions(DELETE, `/clients/${userId}`);
export const getClientEvent = (userId, eventId) => requestOptions(GET, `/clients/${userId}/events/${eventId}`);
export const getClientEvents = (userId) => requestOptions(GET, `/clients/${userId}/events`);
export const getClientNextEvent = (userId) => requestOptions(GET, `/clients/${userId}/events/next`);
export const uploadInternalClientPhoto = (userId, data) => requestOptions(PUT, `/clients/${userId}/photo`, data);
export const switchClientEvent = (userId, eventId) => requestOptions(PUT, `/clients/${userId}/events/${eventId}/switch`);

// Events
export const getEvents = (filter) => requestOptions(GET, `/events?filter=${filter ? filter : "all"}`);
export const getEventsExport = () => requestOptions(GET, `/events/export`);
export const getEvent = (id, level) => requestOptions(GET,`/events/${id}?level=${level ? level : "summary"}`);
export const createEvent = (data) => requestOptions(POST, `/events`, data);
export const updateEvent = (id, data) => requestOptions(PUT, `/events/${id}`, data);
export const deleteEvent = (id) => requestOptions(DELETE, `/events/${id}`);
export const getEventPlaylists = (id) => requestOptions(GET, `/events/${id}/playlists`);
export const getEventPlaylistsPrep = (id) => requestOptions(GET, `/events/${id}/playlists/prep`);
export const getEventPlaylist = (event_id, event_playlist_id) => requestOptions(GET,`/events/${event_id}/playlists/${event_playlist_id}`);
export const createEventPlaylist = (event_id, data) => requestOptions(POST, `/events/${event_id}/playlists`, data);
export const updateEventPlaylist = (event_id, event_playlist_id, data) => requestOptions(PUT,`/events/${event_id}/playlists/${event_playlist_id}`, data);
export const deleteEventPlaylist = (event_id, event_playlist_id) => requestOptions(DELETE,`/events/${event_id}/playlists/${event_playlist_id}`);
export const getEventPlaylistSongs = (event_id, event_playlist_id) => requestOptions(GET, `/events/${event_id}/playlists/${event_playlist_id}/songs`);
export const createEventPlaylistSongs = (event_id, event_playlist_id, data) => requestOptions(POST, `/events/${event_id}/playlists/${event_playlist_id}/songs`, data);
export const createEventPlaylistSongsBulk = (event_id, event_playlist_id, data) => requestOptions(POST, `/events/${event_id}/playlists/${event_playlist_id}/songs/bulk`, data);
export const updateEventPlaylistSongs = (event_id, event_playlist_id, event_playlist_song_id, data) => requestOptions(PUT, `/events/${event_id}/playlists/${event_playlist_id}/songs/${event_playlist_song_id}`, data);
export const deleteEventPlaylistSongs = (event_id, event_playlist_id, event_playlist_song_id) => requestOptions(DELETE, `/events/${event_id}/playlists/${event_playlist_id}/songs/${event_playlist_song_id}`);
export const getEventSpecialEvents = (id) => requestOptions(GET, `/events/${id}/special-events`);

export const getV2EventSpecialEvents = (id) => requestOptions(GET, `/v2/events/${id}/special-events`);
export const getV2EventSpecialEvent = (event_id, special_event_id) => requestOptions(GET, `/v2/events/${event_id}/special-events/${special_event_id}`);
export const getV2EventSpecialEventSection = (event_id, event_special_event_section_id) => requestOptions(GET, `/v2/events/${event_id}/special-events/section/${event_special_event_section_id}`);
export const updateV2EventSpecialEventSectionStartTime = (event_id, event_special_event_section_id, data) => requestOptions(PUT, `/v2/events/${event_id}/special-events/section/${event_special_event_section_id}/start-time`, data);
export const updateV2EventSpecialEventActive = (event_id, special_event_id, data) => requestOptions(PUT, `/v2/events/${event_id}/special-events/${special_event_id}/active`, data);
export const updateV2EventSpecialEventRank = (event_id, special_event_id, data) => requestOptions(PUT, `/v2/events/${event_id}/special-events/${special_event_id}/rank`, data);
export const updateV2EventSpecialEventTimeline = (event_id, special_event_id, data) => requestOptions(PUT, `/v2/events/${event_id}/special-events/${special_event_id}/timeline`, data);
export const updateV2EventSpecialEventTimelineRank = (event_id, special_event_id, data) => requestOptions(PUT, `/v2/events/${event_id}/special-events/${special_event_id}/timeline-rank`, data);
export const createV2EventSpecialEventSong = (event_id, special_event_id, data) => requestOptions(POST, `/v2/events/${event_id}/special-events/${special_event_id}/songs`, data);
export const createV2EventSpecialEventSongsBulk = (event_id, special_event_id, data) => requestOptions(POST, `/v2/events/${event_id}/special-events/${special_event_id}/songs/bulk`, data);
export const updateV2EventSpecialEventSongNote = (event_id, special_event_id, special_event_song_id, data) => requestOptions(PUT, `/v2/events/${event_id}/special-events/${special_event_id}/songs/${special_event_song_id}/notes`, data);
export const updateV2EventSpecialEventSongRank = (event_id, special_event_id, special_event_song_id, data) => requestOptions(PUT, `/v2/events/${event_id}/special-events/${special_event_id}/songs/${special_event_song_id}/rank`, data);
export const deleteV2EventSpecialEventSong = (event_id, special_event_id, special_event_song_id) => requestOptions(DELETE, `/v2/events/${event_id}/special-events/${special_event_id}/songs/${special_event_song_id}`);

export const updateEventSpecialEventActive = (event_id, special_event_id, data) => requestOptions(PUT, `/events/${event_id}/special-events/${special_event_id}/active`, data);
export const updateEventSpecialEventSongLimit = (event_id, special_event_id, data) => requestOptions(PUT, `/events/${event_id}/special-events/${special_event_id}/song-limit`, data);
export const updateEventSpecialEventSongs = (event_id, special_event_id, data) => requestOptions(PUT, `/events/${event_id}/special-events/${special_event_id}/songs`, data);
export const getEventQuestionnaires = (id) => requestOptions(GET, `/events/${id}/questionnaires`);
export const getEventQuestionnairesPreview = (id) => requestOptions(GET, `/events/${id}/questionnaires/preview`);
export const deleteEventQuestionnaire = (event_id, questionnaire_id) => requestOptions(DELETE, `/events/${event_id}/questionnaires/${questionnaire_id}`);
export const createEventQuestionnaire = (id, data) => requestOptions(POST, `/events/${id}/questionnaires`, data);
export const getEventQuestionnaire = (id, questionnaire_id) => requestOptions(GET, `/events/${id}/questionnaires/${questionnaire_id}`);
export const updateEventQuestionnaire = (id, questionnaire_id, data) => requestOptions(PUT, `/events/${id}/questionnaires/${questionnaire_id}`, data);
export const getAccountSpecialEvents = (event_type_id) => requestOptions(GET, `/events/special-events?event_type_id=${event_type_id}`);
export const upsertAccountSpecialEvents = (body) => requestOptions(PUT, `/events/special-events`, body);
export const getEventSpecialEventRecommendedPlaylist = (event_id, special_event_id) => requestOptions(GET, `/events/${event_id}/special-events/${special_event_id}/recommended-playlist`);
export const getV2EventSpecialEventRecommendedPlaylist = (event_id, special_event_id, recommended_playlist_id) => requestOptions(GET, `/v2/events/${event_id}/special-events/${special_event_id}/recommended-playlist/${recommended_playlist_id}`);

export const getEventTimeline = (event_id) => requestOptions(GET, `/events/${event_id}/timeline`);
export const createEventTimeline = (event_id, data) => requestOptions(POST, `/events/${event_id}/timeline`, data);
export const updateEventTimeline = (event_id, data) => requestOptions(PUT, `/events/${event_id}/timeline`, data);
export const deleteEventTimeline = (event_id) => requestOptions(DELETE, `/events/${event_id}/timeline`);

export const getTimelinePreview = (event_timeline_id) => get(`/timelines/${event_timeline_id}/preview`);
export const getAllEventPlaylists = (event_id) => requestOptions(GET, `/playlists/event/${event_id}`);
export const getAllEventSpecialEvents = (event_id) => requestOptions(GET, `/special-events/event/${event_id}`);
export const getEventSheetDetails = (event_id) => get(`/events/${event_id}/details`);
export const getMusicSelectionDetails = (event_id) => get(`/events/${event_id}/music-selections`);
export const getQuestionnaireDetails = (event_id) => get(`/events/${event_id}/data/questionnaires`);
export const getTimelineDetails = (event_id) => get(`/events/${event_id}/data/timeline`);

export const getClientEventRoles = () => requestOptions(GET, `/events/client-event-roles`);
export const getAdminEventRoles = () => requestOptions(GET, `/events/admin-event-roles`);
export const getNewEventData = () => requestOptions(GET, `/events/new-event-data`);

export const uploadEventPhoto = (data) => requestOptions(POST, `/events/photo`, data);

export const createEventEmployee = (event_id, data) => requestOptions(POST, `/events/${event_id}/employees`, data);
export const deleteEventEmployee = (event_id, user_id) => requestOptions(DELETE, `/events/${event_id}/employees/${user_id}`);
export const updateEventEmployee = (event_id, user_id, data) => requestOptions(PUT, `/events/${event_id}/employees/${user_id}`, data);

export const assignEventClient = (event_id, user_id, data) => requestOptions(POST, `/events/${event_id}/clients/${user_id}`, data);
export const createEventClient = (event_id, data) => requestOptions(POST, `/events/${event_id}/clients`, data);
export const getEventClients = (event_id) => requestOptions(GET, `/events/${event_id}/clients`);
export const updateEventClient = (event_id, user_id, data) => requestOptions(PUT, `/events/${event_id}/clients/${user_id}`, data);
export const deleteEventClient = (event_id, user_id) => requestOptions(DELETE, `/events/${event_id}/clients/${user_id}`);

export const assignEventVenue = (event_id, venue_id, data) => requestOptions(POST, `/events/${event_id}/venues/${venue_id}`, data);
export const createEventVenue = (event_id, data) => requestOptions(POST, `/events/${event_id}/venues`, data);
export const deleteEventVenue = (event_id, venue_id) => requestOptions(DELETE, `/events/${event_id}/venues/${venue_id}`);

export const assignEventWorkflow = (event_id, workflow_id) => requestOptions(POST, `/events/${event_id}/workflows/${workflow_id}`);
export const getEventWorkflowSteps = (event_id) => requestOptions(GET, `/events/${event_id}/workflow-steps`);
export const updateEventTaskCompleted = (event_id, event_workflow_step_id, data) => requestOptions(PUT, `/events/${event_id}/workflow-steps/${event_workflow_step_id}/completed`, data);
export const updateEventWorkflowStep = (event_id, event_workflow_step_id, data) => requestOptions(PUT, `/events/${event_id}/workflow-steps/${event_workflow_step_id}`, data);
export const createEventWorkflowStep = (event_id, data) => requestOptions(POST, `/events/${event_id}/workflow-steps`, data);
export const deleteEventWorkflowStep = (event_id, event_workflow_step_id) => requestOptions(DELETE, `/events/${event_id}/workflow-steps/${event_workflow_step_id}`);

export const getEventGenres = (event_id) => requestOptions(GET, `/events/${event_id}/genres`);
export const updateEventGenres = (event_id, data) => requestOptions(POST, `/events/${event_id}/genres`, data);

export const getEventLogs = () => requestOptions(GET, `/events/logs`);

export const getEventContracts = (id) => requestOptions(GET, `/events/${id}/contracts`);
export const getEventContract = (event_id, event_contract_id) => requestOptions(GET, `/events/${event_id}/contracts/${event_contract_id}`);
export const createEventContract = (event_id, data) => requestOptions(POST, `/events/${event_id}/contracts`, data);
export const updateEventContract = (event_id, event_contract_id, data) => requestOptions(PUT, `/events/${event_id}/contracts/${event_contract_id}`, data);
export const updateEventContractTemplate = (event_id, event_contract_id, data) => requestOptions(PUT, `/events/${event_id}/contracts/${event_contract_id}/template`, data);
export const updateEventContractName = (event_id, event_contract_id, data) => requestOptions(PUT, `/events/${event_id}/contracts/${event_contract_id}/name`, data);
export const updateEventContractExpiration = (event_id, event_contract_id, data) => requestOptions(PUT, `/events/${event_id}/contracts/${event_contract_id}/expiration`, data);
export const deleteEventContract = (event_id, event_contract_id) => requestOptions(DELETE, `/events/${event_id}/contracts/${event_contract_id}`);
export const getEventContractMergeTags = (id) => requestOptions(GET, `/events/${id}/merge-tags`);
export const sendEventContract = (event_id, event_contract_id, data) => requestOptions(POST, `/events/${event_id}/contracts/${event_contract_id}/send`, data);
export const updateEventContractStatus = (event_id, event_contract_id, data) => requestOptions(PUT, `/events/${event_id}/contracts/${event_contract_id}/status`, data);
export const revertEventContract = (event_id, event_contract_id) => requestOptions(PUT, `/events/${event_id}/contracts/${event_contract_id}/revert`);
export const getEventContractToSign = (signer_id) => get(`/events/event-contract/${signer_id}`);
export const getEventContractPreview = (event_contract_id) => get(`/events/preview-contract/${event_contract_id}`);
export const signEventContract = (data) => post(`/events/sign-contract`, data);

export const createContractSigner = (event_contract_id, data) => requestOptions(POST, `/contracts/${event_contract_id}/signers`, data);
export const deleteContractSigner = (event_contract_id, event_contract_signer_id) => requestOptions(DELETE, `/contracts/${event_contract_id}/signers/${event_contract_signer_id}`);

export const getAccountSettingsForEvent = (event_id) => requestOptions(GET, `/events/${event_id}/account-settings`);

// Event Packages
export const getEventPackages = (id) => requestOptions(GET, `/events/${id}/packages`);
export const getEventPackage = (id, package_id) => requestOptions(GET, `/events/${id}/packages/${package_id}`);
export const createEventPackage = (id, data) => requestOptions(POST, `/events/${id}/packages`, data);
export const updateEventPackage = (event_id, package_id, data) => requestOptions(PUT, `/events/${event_id}/packages/${package_id}`, data);
export const deleteEventPackage = (event_id, package_id) => requestOptions(DELETE, `/events/${event_id}/packages/${package_id}`);

// Event Add-Ons
export const getEventAddOns = (id) => requestOptions(GET, `/events/${id}/addons`);
export const getEventAddOn = (id, add_on_id) => requestOptions(GET, `/events/${id}/addons/${add_on_id}`);
export const createEventAddOn = (id, data) => requestOptions(POST, `/events/${id}/addons`, data);
export const updateEventAddOn = (event_id, add_on_id, data) => requestOptions(PUT, `/events/${event_id}/addons/${add_on_id}`, data);
export const deleteEventAddOn = (event_id, add_on_id) => requestOptions(DELETE, `/events/${event_id}/addons/${add_on_id}`);

// Event Payments
export const getPayments = (id) => requestOptions(GET, `/events/${id}/payments`);
export const createPayment = (id, data) => requestOptions(POST, `/events/${id}/payments`, data);
export const updatePayment = (event_id, payment_id, data) => requestOptions(PUT, `/events/${event_id}/payments/${payment_id}`, data);
export const deletePayment = (event_id, payment_id) => requestOptions(DELETE, `/events/${event_id}/payments/${payment_id}`);

// Event Financials
export const getEventFinancials = (id) => requestOptions(GET, `/events/${id}/financials`);
export const getV1EventFinancialSummary = (id) => requestOptions(GET, `/events/${id}/financials/summary`);
export const getEventFinancialSummary = (id) => requestOptions(GET, `/v2/events/${id}/financials/summary`);
export const upsertEventFinancials = (id, data) => requestOptions(POST, `/events/${id}/financials`, data);

// Event Metadata
export const getEventMetadata = (id) => requestOptions(GET, `/events/${id}/metadata`);
export const upsertEventMetadata = (id, data) => requestOptions(POST, `/events/${id}/metadata`, data);

// Event Invoices
export const getEventInvoices = (id) => requestOptions(GET, `/events/${id}/invoices`);
export const getEventInvoice = (event_id, id) => requestOptions(GET, `/events/${event_id}/invoices/${id}`);
export const createEventInvoice = (id, data) => requestOptions(POST, `/events/${id}/invoices`, data);
export const updateEventInvoice = (event_id, id, data) => requestOptions(PUT, `/events/${event_id}/invoices/${id}`, data);
export const updateEventInvoiceStatus = (event_id, id, data) => requestOptions(PUT, `/events/${event_id}/invoices/${id}/status`, data);
export const deleteEventInvoice = (event_id, id) => requestOptions(DELETE, `/events/${event_id}/invoices/${id}`);
export const sendEventInvoice = (event_id, id, data) => requestOptions(POST, `/events/${event_id}/invoices/${id}/send`, data);

export const getInvoicePreview = (invoice_id) => get(`/invoices/${invoice_id}`);
export const createStripeCheckoutSession = (data) => post(`/stripe/checkout-session`, data);
export const getStripePaymentIntent = (pi) => get(`/stripe/payment-intent/${pi}`);
export const createPayPalOrder = (data) => post(`/paypal/create-order`, data);
export const capturePayPalPayment = (data) => post(`/paypal/capture-payment`, data);
export const generateClientToken = (data) => post(`/paypal/generate-token`, data);

// Event Invoices v2
export const getEventInvoicesV2 = (id) => requestOptions(GET, `/v2/events/${id}/invoices`);

// Invoices v2
export const getInvoices = (filter = "all") => requestOptions(GET, `/v2/invoices?filter=${filter}`);
export const getInvoice = (id) => requestOptions(GET, `/v2/invoices/${id}`);
export const getInvoicePreviewV2 = (id) => get(`/v2/invoices/${id}/preview`);
export const createInvoice = (data) => requestOptions(POST, `/v2/invoices`, data);
export const deleteInvoice = (id) => requestOptions(DELETE, `/v2/invoices/${id}`);
export const updateInvoice = (id, data) => requestOptions(PUT, `/v2/invoices/${id}`, data);
export const sendInvoice = (id, data) => requestOptions(POST, `/v2/invoices/${id}/send`, data);
export const getInvoicePaymentInfo = (invoice_id) => get(`/v2/invoices/${invoice_id}/payment`);
export const confirmManualPayment = (invoice_id, data) => post(`/v2/invoices/${invoice_id}/manual-payment`, data);
export const updateInvoiceStatus = (id, data) => requestOptions(PUT, `/v2/invoices/${id}/status`, data);
export const createInvoicePayment = (id, data) => requestOptions(POST, `/v2/invoices/${id}/payments`, data);
export const updateInvoicePayment = (id, data) => requestOptions(PUT, `/v2/invoices/payments/${id}`, data);
export const deleteInvoicePayment = (id) => requestOptions(DELETE, `/v2/invoices/payments/${id}`);
export const deletePendingInvoicePayment = (id) => del(`/v2/invoices/payments/${id}/pending`);
export const confirmPendingInvoicePayment = (id, data) => requestOptions(PUT, `/v2/invoices/payments/${id}/pending`, data);
export const getInvoiceTemplates = () => requestOptions(GET, `/v2/invoices/templates`);
export const getInvoiceTemplate = (id) => requestOptions(GET, `/v2/invoices/templates/${id}`);
export const createInvoiceTemplate = (data) => requestOptions(POST, `/v2/invoices/templates`, data);
export const duplicateInvoiceTemplate = (id) => requestOptions(POST, `/v2/invoices/templates/${id}`);
export const updateIvoiceTemplate = (id, data) => requestOptions(PUT, `/v2/invoices/templates/${id}`, data);
export const deleteInvoiceTemplate = (id) => requestOptions(DELETE, `/v2/invoices/templates/${id}`);
export const getLegacyInvoiceSummary = () => requestOptions(GET, `/v2/invoices/legacy-summary`);

// Venues
export const getVenues = (filter = "filtered") => requestOptions(GET, `/venues?filter=${filter}`);
export const getVenue = (id) => requestOptions(GET, `/venues/${id}`);
export const createVenue = (data) => requestOptions(POST, `/venues`, data);
export const updateVenue = (id, data) => requestOptions(PUT, `/venues/${id}`, data);
export const deleteVenue = (id) => requestOptions(DELETE, `/venues/${id}`);

// Special Events
export const getAccountSpecialEventTemplates = () => requestOptions(GET, `/special-events/templates`);
export const getAccountSpecialEventTemplate = (id) => requestOptions(GET, `/special-events/templates/${id}`);
export const deleteAccountSpecialEventTemplate = (id) => requestOptions(DELETE, `/special-events/templates/${id}`);
export const createAccountSpecialEventTemplate = (data) => requestOptions(POST, `/special-events/templates`, data);
export const updateAccountSpecialEventTemplate = (id, data) => requestOptions(PUT, `/special-events/templates/${id}`, data);
export const duplicateAccountSpecialEventTemplate = (id) => requestOptions(POST, `/special-events/templates/${id}/duplicate`);
export const createAccountSpecialEventSection = (id, data) => requestOptions(POST, `/special-events/templates/${id}/sections`, data);
export const updateAccountSpecialEventSection = (template_id, section_id, data) => requestOptions(PUT, `/special-events/templates/${template_id}/sections/${section_id}`, data);
export const deleteAccountSpecialEventSection = (template_id, section_id) => requestOptions(DELETE, `/special-events/templates/${template_id}/sections/${section_id}`);
export const getAccountSpecialEventSections = (id) => requestOptions(GET, `/special-events/templates/${id}/sections`);
export const createAccountSpecialEvent = (template_id, section_id, data) => requestOptions(POST, `/special-events/templates/${template_id}/sections/${section_id}/special-events`, data);
export const updateAccountSpecialEvent = (id, data) => requestOptions(PUT, `/special-events/${id}`, data);
export const deleteAccountSpecialEvent = (id) => requestOptions(DELETE, `/special-events/${id}`);
export const updateAccountSpecialEventRank = (id, data) => requestOptions(PUT, `/special-events/${id}/rank`, data);

export const createEventSpecialEventSection = (event_id, data) => requestOptions(POST, `/events/${event_id}/special-event-sections`, data);
export const updateEventSpecialEventSection = (event_id, section_id, data) => requestOptions(PUT, `/events/${event_id}/special-event-sections/${section_id}`, data);
export const deleteEventSpecialEventSection = (event_id, section_id) => requestOptions(DELETE, `/events/${event_id}/special-event-sections/${section_id}`);
export const createEventSpecialEvent = (event_id, section_id, data) => requestOptions(POST, `/events/${event_id}/special-event-sections/${section_id}/special-events`, data);
export const updateEventSpecialEvent = (event_id, id, data) => requestOptions(PUT, `/events/${event_id}/special-events/${id}`, data);
export const deleteEventSpecialEvent = (event_id, id) => requestOptions(DELETE, `/events/${event_id}/special-events/${id}`);
export const updateEventSpecialEventRank = (event_id, id, data) => requestOptions(PUT, `/events/${event_id}/special-events/${id}/rank`, data);
export const updateEventTemplate = (event_id, id) => requestOptions(PUT, `/events/${event_id}/event-template/${id}`);

// Questionnairres
export const getQuestionnaires = () => requestOptions(GET, `/questionnaires`);
export const getQuestionnaire = (id) => requestOptions(GET, `/questionnaires/${id}`);
export const createQuestionnaire = (data) => requestOptions(POST, `/questionnaires`, data);
export const updateQuestionnaire = (id, data) => requestOptions(PUT, `/questionnaires/${id}`, data);
export const deleteQuestionnaire = (id) => requestOptions(DELETE, `/questionnaires/${id}`);
export const getQuestionnairePreview = (id) => get(`/questionnaires/${id}/preview`);

export const getQuestionnaireTemplates = (filter) => requestOptions(GET, `/questionnaires/templates?filter=${filter ? filter : "all"}`);
export const getQuestionnaireTemplate = (id) => requestOptions(GET, `/questionnaires/templates/${id}`);
export const createQuestionnaireTemplate = (data) => requestOptions(POST, `/questionnaires/templates`, data);
export const updateQuestionnaireTemplate = (id, data) => requestOptions(PUT, `/questionnaires/templates/${id}`, data);

// Packages
export const getPackages = () => requestOptions(GET, `/packages`);
export const getPackage = (id) => requestOptions(GET, `/packages/${id}`);
export const createPackage = (data) => requestOptions(POST, `/packages`, data);
export const updatePackage = (id, data) => requestOptions(PUT, `/packages/${id}`, data);
export const deletePackage = (id) => requestOptions(DELETE, `/packages/${id}`);
export const uploadPackagePhoto = (id, data) => requestOptions(POST, `/packages/${id}/photo`, data);

// Add-Ons
export const getAddOns = () => requestOptions(GET, `/addons`);
export const getAddOn = (id) => requestOptions(GET, `/addons/${id}`);
export const createAddOn = (data) => requestOptions(POST, `/addons`, data);
export const updateAddOn = (id, data) => requestOptions(PUT, `/addons/${id}`, data);
export const deleteAddOn = (id) => requestOptions(DELETE, `/addons/${id}`);
export const uploadAddOnPhoto = (id, data) => requestOptions(POST, `/addons/${id}/photo`, data);

// Add-On Categories
export const getAddOnCategories = () => requestOptions(GET, `/addons/categories`);
export const createAddOnCategory = (data) => requestOptions(POST, `/addons/categories`, data);
export const updateAddOnCategory = (id, data) => requestOptions(PUT, `/addons/categories/${id}`, data);
export const deleteAddOnCategory = (id) => requestOptions(DELETE, `/addons/categories/${id}`);

// Pricing Guides
export const getPricingGuides = () => requestOptions(GET, `/pricing-guides`);
export const getPricingGuide = (id) => requestOptions(GET, `/pricing-guides/${id}`);
export const getPricingGuidePreview = (id) => get(`/pricing-guides/${id}/preview`);
export const createPricingGuide = (data) => requestOptions(POST, `/pricing-guides`, data);
export const updatePricingGuide = (id, data) => requestOptions(PUT, `/pricing-guides/${id}`, data);
export const deletePricingGuide = (id) => requestOptions(DELETE, `/pricing-guides/${id}`);
export const createPricingGuideRequest = (id, data) => post(`/pricing-guides/${id}/requests`, data);
export const getPricingGuideRequests = () => requestOptions(GET, `/pricing-guides/requests`);
export const getPricingGuideRequest = (id) => requestOptions(GET, `/pricing-guides/requests/${id}`);
export const deletePricingGuideRequest = (id) => requestOptions(DELETE, `/pricing-guides/requests/${id}`);

// Contracts
export const getContracts = () => requestOptions(GET, `/contracts`);

// Contract Templates
export const getContractTemplates = () => requestOptions(GET, `/contracts/templates`);
export const getContractTemplate = (id) => requestOptions(GET, `/contracts/templates/${id}`);
export const createContractTemplate = (data) => requestOptions(POST, `/contracts/templates`, data);
export const updateContractTemplateContent = (id, data) => requestOptions(PUT, `/contracts/templates/${id}/content`, data);
export const updateContractTemplateName = (id, data) => requestOptions(PUT, `/contracts/templates/${id}/name`, data);
export const deleteContractTemplate = (id) => requestOptions(DELETE, `/contracts/templates/${id}`);

// Workflows
export const getWorkflows = () => requestOptions(GET, `/workflows`);
export const getWorkflow = (id) => requestOptions(GET, `/workflows/${id}`);
export const createWorkflow = (data) => requestOptions(POST, `/workflows`, data);
export const updateWorkflow = (id, data) => requestOptions(PUT, `/workflows/${id}`, data);
export const deleteWorkflow = (id) => requestOptions(DELETE, `/workflows/${id}`);
export const createWorkflowStep = (workflow_id, data) => requestOptions(POST, `/workflows/${workflow_id}/steps`, data);
export const updateWorkflowStep = (workflow_id, workflow_step_id, data) => requestOptions(PUT, `/workflows/${workflow_id}/steps/${workflow_step_id}`, data);
export const deleteWorkflowStep = (workflow_id, workflow_step_id) => requestOptions(DELETE, `/workflows/${workflow_id}/steps/${workflow_step_id}`);
export const getWorkflowTasks = (filter) => requestOptions(GET, `/workflows/tasks?filter=${filter}`);

export const getSystemWorkflows = (filter) => requestOptions(GET, `/system/workflows?filter=${filter ? filter : "all"}`);
export const getSystemWorkflow = (id) => requestOptions(GET, `/system/workflows/${id}`);
export const createSystemWorkflow = (data) => requestOptions(POST, `/system/workflows`, data);
export const updateSystemWorkflow = (id, data) => requestOptions(PUT, `/system/workflows/${id}`, data);
export const createSystemWorkflowStep = (system_workflow_id, data) => requestOptions(POST, `/system/workflows/${system_workflow_id}/steps`, data);
export const updateSystemWorkflowStep = (system_workflow_id, system_workflow_step_id, data) => requestOptions(PUT, `/system/workflows/${system_workflow_id}/steps/${system_workflow_step_id}`, data);
export const deleteSystemWorkflowStep = (system_workflow_id, system_workflow_step_id) => requestOptions(DELETE, `/system/workflows/${system_workflow_id}/steps/${system_workflow_step_id}`);

// System Leads
export const getSystemLeads = () => requestOptions(GET, `/system/leads`);
export const getSystemLead = (id) => requestOptions(GET, `/system/leads/${id}`);
export const createSystemLead = (data) => requestOptions(POST, `/system/leads`, data);
export const updateSystemLead = (id, data) => requestOptions(PUT, `/system/leads/${id}`, data);
export const sendSystemLeadEmail = (lead_id, data) => requestOptions(POST, `/system/leads/${lead_id}/email`, data);

// System emails
export const sendSystemAccountEmail = (data) => requestOptions(POST, `/system/account-emails`, data);
export const getSystemAccountEmails = () => requestOptions(GET, `/system/account-emails`);

// System Accounts
export const getSystemAccounts = () => requestOptions(GET, `/system/accounts`);
export const getSystemAccount = (id) => requestOptions(GET, `/system/accounts/${id}`);
export const getSystemAccountEvent = (id, event_id) => requestOptions(GET, `/system/accounts/${id}/events/${event_id}`);
export const getSystemRevenue = () => requestOptions(GET, `/system/revenue`);
export const getSystemPaymentLogs = (lastId, limit, account_id) => requestOptions(GET, `/system/payment-logs?last_id=${lastId}&limit=${limit}&account_id=${account_id}`);
export const deleteAccount = (data) => requestOptions(DELETE, `/system/accounts`, data);
export const getSystemInvitationLink = (user_id) => requestOptions(GET, `/system/invitation/${user_id}`);

// Currency
export const getCurrencies = () => requestOptions(GET, `/system/currencies`);

// Leads
export const getLeads = (id) => get(`/leads`);
export const getLead = (id) => get(`/lead/${id}`);

// Proposals
export const getProposal = (id) => get(`/proposal/${id}`);
export const getProposals = () => get(`/proposals`);
export const saveProposal = (body) => post(`/proposal`, body);
export const updateProposal = (id, body) => put(`/proposal/${id}`, body);
export const deleteProposal = (id) => del(`/proposal/${id}`);

// Services
export const getServices = () => get(`/services`);
export const getService = (id) => get(`/services/${id}`);
export const createService = (data) => post(`/services`, data);
export const updateService = (id, data) => put(`/services/${id}`, data);
export const deleteService = (id) => del(`/services/${id}`);
export const uploadServiceImage = (id, data) => postImage(`/services/${id}/image`, data);

// Music
export const searchMusic = (platform, text) => requestOptions(GET, `/music/search?platform=${platform}&q=${text}`);
export const getSystemRecommendedPlaylists = () => requestOptions(GET, `/music/system/recommended-playlists`);
export const getSystemRecommendedPlaylistGroups = () => requestOptions(GET, `/music/system/recommended-playlist-groups`);
export const getSystemRecommendedPlaylist = (id) => requestOptions(GET, `/music/system/recommended-playlists/${id}`);
export const updateSystemRecommendedPlaylist = (id, data) => requestOptions(PUT, `/music/system/recommended-playlists/${id}`, data);
export const getRecommendedPlaylists = () => requestOptions(GET, `/music/recommended-playlists`);
export const getRecommendedPlaylist = (id) => requestOptions(GET, `/music/recommended-playlists/${id}`);
export const createRecommendedPlaylist = (data) => requestOptions(POST, `/music/recommended-playlists`, data);
export const updateRecommendedPlaylist = (id, data) => requestOptions(PUT, `/music/recommended-playlists/${id}`, data);
export const deleteRecommendedPlaylist = (id) => requestOptions(DELETE, `/music/recommended-playlists/${id}`);
export const getSystemRecommendedPlaylistGroupListByKey = (key) => requestOptions(GET, `/music/system/recommended-playlist-group-lists/${key}`);
export const getExternalPlaylist = (data) => requestOptions(POST, `/music/external/playlist`, data);

// System
export const getUserCounts = () => requestOptions(GET, `/system/users/count`);
export const getEventCounts = () => requestOptions(GET, `/system/events/count`);
export const getDashboardInfo = () => requestOptions(GET, `/system/dashboard`);
export const createSystemEventType = (data) => requestOptions(POST, `/system/event-types`, data);
export const updateSystemEventType = (id, data) => requestOptions(PUT, `/system/event-types/${id}`, data);
export const createSystemRecommendedPlaylist = (data) => requestOptions(POST, `/system/recommended-playlists`, data);
export const getSystemSpecialEvents = (event_type_id) => requestOptions(GET, `/system/special-events?event_type_id=${event_type_id}`);
export const createSystemSpecialEvents = (data) => requestOptions(POST, `/system/special-events`, data);
export const updateSystemSpecialEvents = (id, data) => requestOptions(PUT, `/system/special-events/${id}`, data);
export const createSystemRecommendedPlaylistGroup= (data) => requestOptions(POST, `/system/recommended-playlist-groups`, data);
export const updateSystemRecommendedPlaylistGroup = (id, data) => requestOptions(PUT, `/system/recommended-playlist-groups/${id}`, data);
export const getSystemRecommendedPlaylistGroup = (id) => requestOptions(GET, `/system/recommended-playlist-groups/${id}`);
export const getSystemRecommendedPlaylistGroupLists = () => requestOptions(GET, `/system/recommended-playlist-group-lists`);
export const getSystemRecommendedPlaylistGroupList = (id) => requestOptions(GET, `/system/recommended-playlist-group-lists/${id}`);
export const createSystemRecommendedPlaylistGroupList = (data) => requestOptions(POST, `/system/recommended-playlist-group-lists`, data);
export const updateSystemRecommendedPlaylistGroupList = (id, data) => requestOptions(PUT, `/system/recommended-playlist-group-lists/${id}`, data);
export const getSystemPlaylists = () => requestOptions(GET, `/system/playlists`);
export const getRecommendedPlaylistGroupList = (type) => requestOptions(GET, `/music/system/recommended-playlist-group-lists/${type}`);

export const getSystemSpecialEventTemplates = () => requestOptions(GET, `/system/special-event-templates`);
export const getSystemSpecialEventTemplate = (id) => requestOptions(GET, `/system/special-event-templates/${id}`);
export const deleteSystemSpecialEventTemplate = (id) => requestOptions(DELETE, `/system/special-event-templates/${id}`);
export const createSystemSpecialEventTemplate = (data) => requestOptions(POST, `/system/special-event-templates`, data);
export const createSystemSpecialEventSection = (id, data) => requestOptions(POST, `/system/special-event-templates/${id}/sections`, data);
export const updateSystemSpecialEventSection = (template_id, section_id, data) => requestOptions(PUT, `/system/special-event-templates/${template_id}/sections/${section_id}`, data);
export const deleteSystemSpecialEventSection = (template_id, section_id) => requestOptions(DELETE, `/system/special-event-templates/${template_id}/sections/${section_id}`);
export const getSystemSpecialEventSections = (id) => requestOptions(GET, `/system/special-event-templates/${id}/sections`);
export const createSystemSpecialEventRecord = (template_id, section_id, data) => requestOptions(POST, `/system/special-event-templates/${template_id}/sections/${section_id}/special-events`, data);
export const updateSystemSpecialEventRecord = (id, data) => requestOptions(PUT, `/system/special-event-records/${id}`, data);
export const deleteSystemSpecialEventRecord = (id) => requestOptions(DELETE, `/system/special-event-records/${id}`);
export const updateSystemSpecialEventRank = (id, data) => requestOptions(PUT, `/system/special-event-records/${id}/rank`, data);

export const getSystemGenres = () => requestOptions(GET, `/system/genres`);

export const updateAccountStatus = (account_id, data) => requestOptions(PUT, `/system/accounts/${account_id}/status`, data);
export const updateAccountTrialExpiration = (account_id, data) => requestOptions(PUT, `/system/accounts/${account_id}/trial-expiration`, data);

// Notifications
export const getUserNotificationPreferences = () => requestOptions(GET, `/notifications/preferences`);
export const updateUserNotificationPreferences = (notification_preference_id, data) => requestOptions(PUT, `/notifications/preferences/${notification_preference_id}`, data);

// Reports
export const getSpecialEventSongsReport = () => requestOptions(GET, `/reports/special-event-songs`);
export const getProfitAndLossStatement = (filter) => requestOptions(GET, `/reports/profit-and-loss?filter=${filter}`);
export const getEventReport = (filter, range) => requestOptions(GET, `/reports/events?filter=${filter}&range=${range}`);
export const getMonthlyIncomeReport = (filter) => requestOptions(GET, `/reports/monthly-income-summary?filter=${filter}`);

// Spotify
export const spotifyLogout = () => requestOptions(POST, `/spotify/logout`);
export const getSpotifyUserProfile = () => requestOptions(GET, `/spotify/profile`);
export const getSpotifyAuthStatus = () => requestOptions(GET, `/spotify/status`);
export const saveSpotifyAccessToken = (data) => requestOptions(POST, `/spotify/access-token`, data);
export const createSpotifyPlaylist = (data) => requestOptions(POST, `/spotify/playlist`, data);

// Calendar
export const getCalendarLinks = () => requestOptions(GET, `/calendar/links`);
export const createCalendarLink = (data) => requestOptions(POST, `/calendar/links`, data);
export const updateCalendarLink = (calendar_id, data) => requestOptions(PUT, `/calendar/links/${calendar_id}`, data);
export const deleteCalendarLink = (calendar_id) => requestOptions(DELETE, `/calendar/links/${calendar_id}`);

// Beta Features
export const getAccountBetaFeatures = () => requestOptions(GET, `/auth/beta-features`);
export const getSystemBetaFeatures = () => requestOptions(GET, `/system/beta-features`);
export const createSystemAccountBetaFeature = (data) => requestOptions(POST, `/system/account-beta-features`, data);
export const deleteSystemAccountBetaFeature = (data) => requestOptions(DELETE, `/system/account-beta-features`, data);

// System Migration
export const getSystemEventInvoices = () => requestOptions(GET, `/system/migration/event-invoices`);
export const getSystemAccountInvoices = (account_id) => requestOptions(GET, `/system/migration/account-events-invoices/${account_id}`);
export const convertInvoice = (invoice_id) => requestOptions(POST, `/system/migration/convert-invoice/${invoice_id}`);

// Sendgrid
export const createContactsSendgrid = (data) => requestOptions(POST, `/sendgrid/contacts`, data);
export const getContactImportStatus = (job_id) => requestOptions(GET, `/sendgrid/contacts/import/${job_id}`);