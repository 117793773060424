import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import { Menu, Row, Col, Dropdown, Grid, Drawer } from 'antd';
import { startsWith, isEmpty } from 'lodash';
import { FiMusic, FiHome, FiUser, FiMenu, FiUsers, FiCalendar, FiUserPlus, FiFlag, FiCheckSquare, FiMapPin, FiHelpCircle, FiInbox, FiSettings, FiDollarSign, FiUserCheck } from "react-icons/fi";
import { MdOutlineClose, MdOutlineQueueMusic, MdPeople, MdOutlineAccountCircle, MdAttachMoney, MdOutlineEmail } from "react-icons/md";
import { ImMobile2 } from "react-icons/im";
import { AiOutlineLineChart, AiOutlineTag } from "react-icons/ai";
import { FaSignature } from "react-icons/fa";
import { HiOutlineTemplate } from "react-icons/hi";
import AppContext from '../../app/context';
import useBetaFeatures from '../../hooks/useBetaFeatures';
import '../clientLayout/clientLayout.style.scss';
import djpcLogoVertical from '../../images/djpc-logo-vertical.png';
import Roles from '../../constants/roles';
import FeatureFlags from '../../constants/featureFlags';
import BetaFeatures from '../../constants/betaFeatures';
import Permissions from '../../constants/permissions';
import { hasPermission } from '../../helpers/permissionHelper';
import { removeBetaFeatures } from '../../helpers/betaFeatureHelper';
import { getDeviceId } from '../../helpers/deviceHelper';
import { logout } from '../../api';

import { UserOutlined, DashboardOutlined, LogoutOutlined, FileTextOutlined } from '@ant-design/icons';

const AdminLayout = () => {

  const [isNewInvoicesEnabled, setNewInvoicesEnabled] = useState(false);
  const [isContractsEnabled, setContractsEnabled] = useState(false);

  const { auth, setAuth } = useContext(AppContext);
  const user = auth.user ? auth.user : {}

  const [isDrawerVisible, setDrawerVisible] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const [hasBetaFeature] = useBetaFeatures()

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  // Template Permissions
  const canManageEventTemplates = hasPermission(user, Permissions.MANAGE_SPECIAL_EVENTS)
  const canManageContractTemplates = hasPermission(user, Permissions.MANAGE_CONTRACT_TEMPLATES)
  const canManageQuestionnaires = hasPermission(user, Permissions.MANAGE_QUESTIONNAIRES)
  const canManageWorkflows = hasPermission(user, Permissions.MANAGE_WORKFLOWS)
  const canManageRecPlaylists = hasPermission(user, Permissions.MANAGE_REC_PLAYLISTS)
  const canManageTemplates = canManageEventTemplates || canManageContractTemplates || canManageQuestionnaires || canManageWorkflows || canManageRecPlaylists

  // Service Permissions
  const canManagePackages = hasPermission(user, Permissions.MANAGE_PACKAGES)
  const canManageAddOns = hasPermission(user, Permissions.MANAGE_ADD_ONS)
  const canManagePricingGuides = hasPermission(user, Permissions.MANAGE_PRICING_GUIDES)
  const canManageServices = canManagePackages || canManageAddOns || canManagePricingGuides

  // Company Permissions
  const canManageCompany = user.role == Roles.OWNER

  const canViewSetup = canManageTemplates || canManageServices || canManageCompany

  useEffect(() => {
    window.scrollTo(0, 0);
    loadPage()
  }, []);

  const loadPage = async () => {
    try {
      const betaResults = await hasBetaFeature(BetaFeatures.INVOICES)
      setNewInvoicesEnabled(FeatureFlags.INVOICES || betaResults)
      const contractsbetaResults = await hasBetaFeature(BetaFeatures.CONTRACTS)
      setContractsEnabled(FeatureFlags.CONTRACTS || contractsbetaResults)
    } catch {}
  }

  const onLogout = async () => {
    try {
      if (user.user_id) {
        await logout(user.user_id, getDeviceId())
      }
      setDrawerVisible(false)
      setAuth({})
      removeBetaFeatures()
    } catch (error) {
      console.log(error)
    }
  }

  const onNavigate = (path) => {
    navigate(path)
    setDrawerVisible(false)
  }

  const menu = () => {
    return (
      <Menu>
        <Menu.Item>
          <div onClick={() => navigate("/admin/account")}>
            <UserOutlined style={{ marginRight: 8}}/> My Account
          </div>
        </Menu.Item>
        <Menu.Item>
          <div onClick={onLogout}>
            <LogoutOutlined style={{ marginRight: 8}}/> Sign Out
          </div>
        </Menu.Item>
      </Menu>
    )
  };

  const renderMobileMenuIcon = () => {
    if (!screens.md) {
      return (
        <Col>
          <div className="display-flex" onClick={() => setDrawerVisible(true)}>
            <FiMenu size={24}/>
          </div>
        </Col>
      )
    }
  }

  const renderUserIcon = () => {
    if (screens.md) {
      return (
        <Col>
          <Dropdown overlay={menu} placement="bottomRight" trigger="click">
            <div>
              <div className="profile-container">
                { auth.user && auth.user.photo ? (
                  <div className="small-profile-photo" style={{ backgroundImage: `url(${auth.user.photo})`}}></div>
                ) : (
                  <div className="profile-circle">
                    <UserOutlined />
                  </div>
                )}
                { !isEmpty(auth.user.alias) ? (
                  <div className="profile-name">{ auth.user.alias }</div>
                ) : (
                  <div className="profile-name">{ auth.user.first_name } { auth.user.last_name }</div>
                )}
              </div>
            </div>
          </Dropdown>
        </Col>
      )
    }
  }

  const renderHeader = () => {
    return (
      <Row align="middle" gutter={[10,10]} className="flex-1">
        <Col>
          <div className="user-layout--logo" onClick={() => navigate("/")}>
            <img src={djpcLogoVertical}/>
          </div>
        </Col>
        <Col flex={1}>
        </Col>
        { renderUserIcon() }
        { renderMobileMenuIcon() }
      </Row>
    )
  }

  const NavMenuItem = (props) => {
    const { title, icon, path } = props;
    const selectedClass = startsWith(location.pathname, path) ? "selected" : ""
    return (
      <div className={`nav-menu-item ${selectedClass}`} onClick={() => onNavigate(path)}>
        <div className="nav-menu-item--icon">
          { icon }
        </div>
        <div className="nav-menu-item--title">
          { title }
        </div>
      </div>
    )
  }

  const renderMenu = () => {
    if (auth.user && auth.user.role == Roles.SYSTEM_ADMIN) {
      return (
        <>
          <NavMenuItem title="Dashboard" icon={<DashboardOutlined />} path="/system/dashboard"/>
          <NavMenuItem title="Event Types" icon={<FiCalendar />} path="/system/event-types"/>
          <NavMenuItem title="Recommended Playlists" icon={<FiMusic />} path="/system/recommended-playlists"/>
          <NavMenuItem title="Special Events" icon={<FiFlag />} path="/system/special-events"/>
          <NavMenuItem title="Playlist Groups" icon={<MdOutlineQueueMusic />} path="/system/recommended-playlist-groups"/>
          <NavMenuItem title="Playlist Group Lists" icon={<MdOutlineQueueMusic />} path="/system/recommended-playlist-group-lists"/>
          <NavMenuItem title="Question Templates" icon={<HiOutlineTemplate />} path="/system/questionnaire-templates"/>
          <NavMenuItem title="Workflow Templates" icon={<HiOutlineTemplate />} path="/system/workflows"/>
          <NavMenuItem title="Leads" icon={<MdPeople />} path="/system/leads"/>
          <NavMenuItem title="Accounts" icon={<MdOutlineAccountCircle />} path="/system/accounts"/>
          <NavMenuItem title="Payment Logs" icon={<MdAttachMoney />} path="/system/payments"/>
          <NavMenuItem title="Revenue" icon={<MdAttachMoney />} path="/system/revenue"/>
          <NavMenuItem title="SE Templates" icon={<HiOutlineTemplate />} path="/system/special-event-templates"/>
          <NavMenuItem title="Emails" icon={<MdOutlineEmail />} path="/system/emails"/>
          <NavMenuItem title="Event Invoices" icon={<MdAttachMoney />} path="/system/event-invoices"/>
          <NavMenuItem title="Beta Features" icon={<FiUserCheck />} path="/system/beta-features"/>
          <NavMenuItem title="SendGrid" icon={<FiUserCheck />} path="/system/sendgrid"/>
        </>
      )
    } else {
      return (
        <>
          <NavMenuItem title="Dashboard" icon={<FiHome />} path="/admin/dashboard"/>
          <NavMenuItem title="Tasks" icon={<FiCheckSquare />} path="/admin/tasks"/>
          { canManagePricingGuides && (
            <NavMenuItem title="Pricing Guides" icon={<AiOutlineTag />} path="/admin/pricing-guides"/>
          )}
          { hasPermission(user, Permissions.VIEW_BOOKING_REQUESTS) && (
            <NavMenuItem title="Booking Requests" icon={<FiInbox />} path="/admin/booking-requests"/>
          )}
          <NavMenuItem title="Events" icon={<FiCalendar />} path="/admin/events"/>
          { isContractsEnabled && hasPermission(user, Permissions.MANAGE_CONTRACTS) && (
             <NavMenuItem title="Contracts" icon={<FaSignature />} path="/admin/contracts"/>
          )}
          { isNewInvoicesEnabled && hasPermission(user, Permissions.MANAGE_INVOICES) && (
             <NavMenuItem title="Invoices" icon={<FiDollarSign />} path="/admin/invoices"/>
          )}
          { hasPermission(user, Permissions.MANAGE_CLIENTS) && (
             <NavMenuItem title="Clients" icon={<FiUsers />} path="/admin/clients"/>
          )}
          { hasPermission(user, Permissions.MANAGE_EMPLOYEES) && (
            <NavMenuItem title="Staff" icon={<FiUserPlus />} path="/admin/staff"/>
          )}
          { hasPermission(user, Permissions.MANAGE_VENUES) && (
            <NavMenuItem title="Venues" icon={<FiMapPin />} path="/admin/venues"/>
          )}
          <NavMenuItem title="Account" icon={<FiUser />} path="/admin/account"/>
          { canViewSetup && (
            <NavMenuItem title="Setup" icon={<FiSettings />} path="/admin/setup"/>
          )}
          { canManageCompany && (
            <NavMenuItem title="Reports" icon={<AiOutlineLineChart />} path="/admin/reports"/>
          )}
          <div className="b-border mv-10"></div>
          <NavMenuItem title="Help & Support" icon={<FiHelpCircle />} path="/admin/support"/>
          <NavMenuItem title="Documentation" icon={<FileTextOutlined />} path="/admin/docs"/>
          <NavMenuItem title="Mobile App" icon={<ImMobile2 />} path="/admin/mobile-app"/>
        </>
      )
    }
  }

  const renderSider = () => {
    if (screens.md) {
      return (
        <div className="user-layout--sider">
          { renderMenu() }
        </div>
      )
    }
  }

  const renderMain = () => {
    const paddingLeft = screens.md ? "240px" : "";
    return (
      <div className="user-layout--main" style={{ paddingLeft: paddingLeft }}>
        <Outlet/>
      </div>
    )
  }

  return (
    <div className="user-layout">
      <div className="user-layout--header">
        { renderHeader() }
      </div>
      <div className="user-layout--body">
        { renderSider() }
        { renderMain() }
      </div>
      <Drawer placement="right" width={240} closable={false} onClose={() => setDrawerVisible(false)} visible={isDrawerVisible} bodyStyle={{ padding: 0, overflowX: 'hidden' }}>
        <Row align="middle" className="p-20">
          <Col flex={1}>
          </Col>
          <Col>
            <div className="display-flex" onClick={() => setDrawerVisible(false)}><MdOutlineClose size={30} color={"#CCC"}/></div>
          </Col>
        </Row>
        <div className="mobile-profile mb-10">
          { auth.user && auth.user.photo ? (
            <div className="small-profile-photo size-80" style={{ backgroundImage: `url(${auth.user.photo})`}}></div>
          ) : (
            <div className="mobile-profile-circle">
              <UserOutlined />
            </div>
          )}
          <div className="fw-700 mt-10">{ auth.user && auth.user.first_name } {auth.user && auth.user.last_name }</div>
        </div>
        { renderMenu() }
        { !screens.md && (
          <div className="ph-20 mt-10 mb-20 pt-20 t-border">
            <button className="page-title-button" style={{ width: '100%'}} onClick={() => onLogout()}>Sign Out</button> 
          </div>
        )}
      </Drawer>
    </div>
  );
};

export default AdminLayout;

